<template>
  <el-form size="mini" ref="form" :model="dept" :rules="formDepts" label-width="120px" status-icon>
    <el-form-item label="部门名称" prop="name">
      <el-input v-model="dept.name" placeholder="请输入部门名称"></el-input>
    </el-form-item>
    <el-form-item label="父级部门" prop="parentId">
      <el-cascader
        v-model="dept.value"
        :options="allDepts"
        :props="props"
        filterable
        clearable
        placeholder="请选择父级部门"
        @change="handleChange"
        :show-all-levels="true"
        ></el-cascader>
    </el-form-item>
    <el-form-item label="部门类型" prop="level">
      <el-select v-model="dept.level" placeholder="请选择部门类型">
        <el-option v-for="(item,index) in levels" :key="index" :label="item.name" :value="item.value"></el-option>
      </el-select>
    </el-form-item>
    <!-- <el-form-item label="排序" prop="sort">
      <el-input v-model="dept.sort" placeholder="请输入sort"></el-input>
    </el-form-item> -->
    <el-form-item label="备注" prop="remark">
      <el-input type="textarea" rows="3" v-model="dept.remark" maxlength="100" show-word-limit placeholder="请输入备注"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button v-if="id" type="primary" @click="handleSubmit(true)" v-waves>保存</el-button>
      <el-button v-if="!id" type="primary" @click="handleSubmit(true)" v-waves>保存并继续</el-button>
      <el-button v-if="!id" type="primary" @click="handleSubmit(false)" v-waves>保存并退出</el-button>
      <el-button v-waves @click="handleCancel">取消</el-button>
    </el-form-item>
  </el-form>
</template>

<style scoped>
  .el-form {
    margin: 20px;
  }

</style>

<script>
import deptAddApi from '@/apis/dept/deptAdd'
import deptInfoApi from '@/apis/dept/deptInfo'
import commonApi from '@/apis/common'
import waves from '@/directive/waves' // 水波纹指令
export default {
  directives: {
    waves
  },
  props:['id'],
  data () {
    return {
      dept: {
        id: null,
        name: '',
        parentId: null,
        sort: 10,
        level: 1,
        value:null,
        path: ''
      },
      parentId: null,
      props: {
        value: 'id',
        label: 'name',
        children: 'children',
        checkStrictly: true
      },
      levels: [
        { name: "根部门", value: 0 },
        { name: "普通部门", value: 1 },
        { name: "叶子部门", value: 2 }
      ],
      allDepts: [],
      formDepts: {
        name: [{
          required: true,
          message: '请输入部门名称'
        },
        {
          max: 20,
          message: '部门名称不超过20字符'
        },
        {
          validator: (rule, value, callback) => {
              commonApi.verifyDeptName(value, this.id).then(res => {
                if (!res.data) {
                  callback(new Error('部门名称' + value + '已存在'))
                } else {
                  callback()
                }
              })
            },
            trigger: 'blur'
        }],
        level:[{
          required: true,
          message: '请选择部门类型'
        }]
      }
    }
  },
  watch: {
    menuId () {
      this.resetForm()
    }
  },
  components: {},
  methods: {
    /**
       * 提交数据
       * @param {*} goon 新增后是否继续新增
       */
    handleSubmit (goon) {
      this.$refs.form.validate().then(response => {
        if (!response) {
          return
        }
        if (!this.id) {
          deptAddApi.insert(this.dept).then(response => {
            this.$message({
              title: '成功',
              message: '部门创建成功',
              type: 'success'
            })
            if (goon) {
              this.resetForm()
              this.getAllDept()
            } else {
              this.$router.replace('/org/dept/list')
            }
          })
        } else {
          deptInfoApi.update(this.dept).then(response => {
            this.$message({
              title: '成功',
              message: '部门更新成功',
              type: 'success'
            })
            this.$router.replace('/org/dept/list')
          })
        }
      })
    },
    /**
       * 取消按钮处理
       */
    handleCancel () {
      this.$router.replace('/org/dept/list')
    },
    /**
       * 获取部门信息
       */
    getDept () {
      if (this.id) {
        deptInfoApi.getById(this.id).then(response => {
          this.dept = response.data
          this.parentId = this.dept.parentId
          this.dept.value = this.parentId
          // if (this.dept.parentId) {
          //   this.getParentId(this.dept)
          // }
        })
      }
    },
    /**
     * 获取父级ID数组
     */
    getParentId (dept) {
      // 往数组头部添加parentId
      this.dept.value.unshift(dept.parentId)
      let parent = {
        id: '',
        parentId: ''
      }
      parent = new Promise((resolve, reject) => {
        deptInfoApi.getById(dept.parentId).then(response => {
          resolve(response.data)
        })
      })
      parent.then(data => {
        if (data.parentId) {
          this.getParentId(data)
        }
      })
    },
    /**
     * 获取所有部门信息
     */
     getAllDept () {
      if (this.id) {
        commonApi.getAllDepts().then(response => {
          this.allDepts = response.data
          // 过滤掉当前id
          this.filterEmpty(this.allDepts)
        })
      } else {
         commonApi.getAllDepts().then(response => {
          this.allDepts = response.data
          this.filterEmpty(this.allDepts)
        })
      }
    },

    filterThisId(data){
       for (var i = 0; i < data.length; i++) {
          if (data[i].id === this.id) {
            data.splice(i, 1)
          }
      }
    },
    filterEmpty(data) {
      if (Array.isArray(data) && data.length > 0) {
        this.filterThisId(data)
        data.forEach(a => {
          if (!Array.isArray(a.children) || a.children.length < 1 || a.children.filter(d => d.id !== this.id).length < 1) {
            a.children = null
            a.leaf = true
          } else {
            this.filterEmpty(a.children)
          }
        })
      }
        
    },

    /**
     * 级联部门change事件处理
     */
    handleChange (value) {
      if(Array.isArray(value)){
        this.dept.parentId = value[value.length - 1]
        this.parentId = this.dept.parentId
      }
    },
    // /**
    //    * 重置表单
    //    */
    // resetForm () {
    //   this.$refs.form.resetFields()
    //   this.dept.id = null
    //   this.dept.parentId = this.parentId
    // },
    /**
     * 加载节点
     */
    loadNode (node, resolve) {
      if (node.level === 0) {
        return resolve([{ name: 'region' }])
      }
      if (node.level > 1) return resolve([])

      setTimeout(() => {
        const data = [{
          name: 'leaf',
          leaf: true
        }, {
          name: 'zone'
        }]
        resolve(data)
      }, 500)
    },

        /**
       * 重置表单
       */
    resetForm () {
      this.$refs.form.resetFields()
      this.dept.parentId = this.parentId
      this.dept.id = ''
      this.dept.value= ''
      this.dept.id = null
      this.dept.value= null
    }

  },
  created () {
    this.getDept()
    this.getAllDept()
  }
}

</script>
